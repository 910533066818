import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import * as xlsx from "xlsx";
import { Back, Loader } from "../template";
import { RiCheckLine } from "react-icons/ri";
import objectPath from "object-path";
import { successAlert } from "../providers/alert";
import { useApp } from "../providers/app";
import { mainApi } from "../providers/api";

const COLUMNS = [
  {
    label: "Host ID",
    field: "host_id",
  },
  {
    label: "Host Name",
    field: "host_name",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "antine",
    field: "antine",
  },
  {
    label: "lane",
    field: "lane",
  },
  {
    label: "direction",
    field: "direction",
  },
  {
    label: "location",
    field: "location",
  },
];
const RFIDExcel = () => {
  const { id } = useParams();
  const { user } = useApp();
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setdata(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const save = async () => {
    setloading(true);
    const result = await mainApi({
      url: "/device/excel",
      method: "POST",
      data: {
        list: data,
      },
    });
    if (result) {
      successAlert();
      navigate(-1);
    }
    setloading(false);
  };
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <Back />
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : (
            <button onClick={save} className="secondary">
              {"Хадгалах"}
              <RiCheckLine className="ml-1 text-lg" />
            </button>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-5 bg-white p-8">
        <form>
          <input
            type="file"
            className="p-3 px-4 w-full rounded-lg"
            name="upload"
            placeholder="asdqwe"
            id="upload"
            onChange={readUploadFile}
          />
        </form>
        {data && (
          <div>
            <div className="flex justify-end p-2">
              <div className="font-bold">{`Нийт : ${data.length}`}</div>
            </div>
            <table className="min-w-full leading-none text-left">
              <thead>
                <tr>
                  {COLUMNS.map((row, index) => {
                    return (
                      <th className="border p-2" key={index}>
                        {row.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => {
                  return (
                    <tr key={index}>
                      {COLUMNS.map(({ field }, index) => {
                        return (
                          <td className="border p-2" key={index}>
                            {objectPath.get(row, `${field}`)}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default RFIDExcel;
