import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import moment from "moment";
import { DATE_FORMAT, YEAR } from "../defines";
import objectPath from "object-path";
import { useApp } from "../providers/app";
import { Image } from "antd";

export const checkFrom = (from) => {
  switch (from) {
    case "rfid":
      return "RFID";

    case "citylpr":
      return "CityLPR";

    case "police":
      return "Цагдаа";

    case "tollgate":
      return "ТАЦ";
    case "traffic":
      return "ЗХУТ";
    default:
      return "Бусад";
  }
};

const Index = () => {
  const { user } = useApp();

  return (
    <div className="flex flex-col ">
      <div className="flex items-center w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 text-black ">
          Зөрчил
        </div>
      </div>
      <div className="px-4">
        <Table
          url={`/violation/table`}
          excelUrl={`/violation/excel`}
          defaultFind={
            user?.role == "statistic" ? { device_id: user?.grouplist } : {}
          }
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Улсын №`,
              key: `plate`,
              sort: true,

              filter: {
                type: "plate",
              },
              render: ({ _id, plate }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div className="text-odt-primary font-bold w-8">{plate}</div>
                  // </Link>
                );
              },
            },

            {
              label: `Систем огноо`,
              sort: true,
              key: `created`,
              filter: {
                type: "date",
              },
              render: ({ _id, created }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div className="">
                    {moment(created).format(DATE_FORMAT).toString()}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Зөрчил огноо`,
              sort: true,
              key: `created`,

              filter: {
                type: "date",
              },
              render: ({ _id, created }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div className="">{moment(created).format(DATE_FORMAT)}</div>
                  // </Link>
                );
              },
            },
            {
              label: `Систем`,
              sort: true,
              key: `from`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "RFID",
                    value: "rfid",
                  },
                  {
                    label: "CityLPR",
                    value: "citylpr",
                  },
                  {
                    label: "Цагдаа",
                    value: "police",
                  },
                  {
                    label: "Tollgate",
                    value: "tollgate",
                  },
                  {
                    label: "ЗХУТ",
                    value: "traffic",
                  },
                ],
              },
              render: ({ _id, from }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div>{checkFrom(from)}</div>
                  // </Link>
                );
              },
            },
            {
              label: `Зураг`,
              key: `photo`,
              sort: true,
              center: true,
              render: ({ _id, photo, from }) => {
                const url = `https://images.weserv.nl/?url=${photo}`;
                return (
                  <Image
                    width={150}
                    crossOrigin="anonymous"
                    src={from == "rfid" ? "../images/rfid.png" : photo}
                  />
                );
              },
            },
            {
              label: `Марк`,
              key: `mark`,
              sort: true,
              filter: {
                type: "text",
              },
            },

            {
              label: `Модел`,
              key: `model`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Төхөөрөмж`,
              key: `device_id`,
              sort: true,
              filter: {
                type: "select",
                axio: "/device/list",
                axioAdapter: (data) => {
                  return data?.map(({ _id, name }) => ({
                    value: _id,
                    label: name,
                  }));
                },
              },

              center: true,
              render: ({ _id, device }) => {
                const name = objectPath.get(device, "name");
                const direction = objectPath.get(device, "direction");
                const lane = objectPath.get(device, "lane");
                return (
                  <div>
                    <div>{name}</div>
                    <div>{direction}</div>
                    <div>{lane}</div>
                  </div>
                );
              },
            },
            {
              label: `Хязгаарлалт`,
              sort: true,
              key: `limit`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, limit }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      limit ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {limit ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Татвар`,
              sort: true,
              key: `tax`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, tax }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      tax ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {tax ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Даатгал`,
              sort: true,
              key: `insurance`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, insurance }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      insurance ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {insurance ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Оншилгоо`,
              sort: true,
              key: `inspection`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, inspection }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      inspection ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {inspection ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Торгууль`,
              sort: true,
              key: `penalty`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, penalty }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      penalty ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {penalty ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Хурд`,
              sort: true,
              key: `speed`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, speed }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      speed ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {speed ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Жолоодох эрхгүй`,
              sort: true,
              key: `drunk`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, drunk }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      drunk ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {drunk ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
            {
              label: `Эрэн сурвалжлалт`,
              sort: true,
              key: `asap`,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Зөрчилтэй",
                    value: true,
                  },
                  {
                    label: "Зөрчилгүй",
                    value: "false",
                  },
                ],
              },
              render: ({ _id, asap }) => {
                return (
                  // <Link to={`view/${_id}`}>
                  <div
                    className={`${
                      asap ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {asap ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  // </Link>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Index;
