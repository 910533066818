import objectPath from "object-path";
import { mainApi } from "../providers/api";
import React, { useState, useEffect } from "react";
import { VscLocation } from "react-icons/vsc";
import { BiCameraHome } from "react-icons/bi";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Loader } from "../template";
import { checkFrom } from "../violation";
const paginationItemclass =
  "h-10 w-10 flex items-center justify-center m-1 cursor border text-sm rounded";
const Item = ({ data }) => {
  const from = objectPath.get(data, "from");
  const photo = objectPath.get(data, "photo");
  const drunk = objectPath.get(data, "drunk");
  const asap = objectPath.get(data, "asap");
  const inspection = objectPath.get(data, "inspection");
  const insurance = objectPath.get(data, "insurance");
  const limit = objectPath.get(data, "limit");
  const penalty = objectPath.get(data, "penalty");
  const speed = objectPath.get(data, "speed");
  const tax = objectPath.get(data, "tax");
  const created = objectPath.get(data, "created");
  const device = objectPath.get(data, "device.name");
  const direction = objectPath.get(data, "device.direction");

  return (
    <div className="flex bg-white rounded shadow p-4 w-full hover:border border-odt-primary mt-2">
      <img
        src={photo}
        className="w-32 min-w-32 bg-gray-500 rounded bg-contain"
      />
      <div className="flex flex-col w-full">
        <div className="flex pl-2 items-center justify-between">
          <div className="flex items-center">
            <BiCameraHome />
            <div className="font-normal text-sm pl-2">{checkFrom(from)}</div>
          </div>
          <div className="font-normal text-sm pl-2">
            {moment(created).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        </div>
        <div className="flex pl-2 items-center">
          <VscLocation />
          <div className="font-normal text-sm pl-2">{device}</div>
        </div>
        <div className="flex flex-wrap pl-1">
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              tax ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Татвар`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              insurance ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Даатгал`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              inspection ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Оншилгоо`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              penalty ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Торгууль`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              limit ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Дугаарын хязгаарлалт`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              speed ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Дундаж хурд`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              asap ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Эрэн сурвалжлалт`}</div>
          <div
            className={`justify-center items-center mx-1 px-3 py-1 text-sm font-medium rounded-full text-white mt-1 ${
              drunk ? "bg-red-500" : "bg-green-500"
            }`}
          >{`Жолоодох эрх`}</div>
        </div>
      </div>
    </div>
  );
};
const List = ({ plate }) => {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [offset, setoffset] = useState(0);
  const [limit, setlimit] = useState(5);
  const [total, settotal] = useState(0);

  const fetch = async () => {
    setloading(true);
    const response = await mainApi({
      url: `/violation/table`,
      method: "POST",
      data: {
        find: {
          plate: plate,
        },
        sort: { _id: -1 },
        limit: limit,
        offset: offset,
      },
    });

    setdata(objectPath.get(response, "data.items"));
    settotal(objectPath.get(response, "data.total"));
    setloading(false);
  };
  useEffect(() => {
    fetch();
  }, [offset]);
  return (
    <div className="flex w-full">
      <div className="flex flex-col min-w-full">
        {loading ? (
          <div className="flex w-full justify-center items-center min-h-60">
            <Loader />
          </div>
        ) : (
          data?.map((row, index) => {
            return <Item data={row} key={index} />;
          })
        )}
        <div className="flex flex-wrap justify-center items-center mt-5 text-sm">
          <ReactPaginate
            onPageChange={(e) => setoffset(e.selected)}
            pageCount={Math.ceil(total / limit)}
            containerClassName="flex flex-wrap justify-center items-center select-none mx-2 text-black "
            nextLabel=">"
            previousLabel="<"
            nextLinkClassName={paginationItemclass}
            previousLinkClassName={paginationItemclass}
            pageLinkClassName={paginationItemclass}
            breakLinkClassName={paginationItemclass}
            activeLinkClassName="bg-odt-primary text-white"
            disabledClassName="cursor-not-allowed"
          />

          <div className="text-black ">
            {`Нийт `}: {total}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
