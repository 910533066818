import moment from "moment";
import objectPath from "object-path";
const strToCurrency = (val) => {
  return val;
};
export const checkTax = (data) => {
  const val = objectPath.get(data, "return.response.listData");

  const current = val?.filter((row) => row.year == moment().year().toString());

  const air = objectPath.get(current, "0.airPollAmount");
  const tax = objectPath.get(current, "0.taxAmount");
  const trafic = objectPath.get(current, "0.trafficAmount");
  const year = objectPath.get(current, "0.year");

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="flex ">
        {
          <div className="font-bold">{`${
            year ? year : moment().year().toString()
          }`}</div>
        }
        <div
          className={`font-bold ${year ? "text-green-500" : "text-red-500"}`}
        >{`-н татвар ${year ? "төлсөн" : "төлөөгүй"}`}</div>
      </div>
      {year && (
        <div className="flex w-full ">
          <div className="flex flex-col w-1/2">
            <div className="flex w-full">
              <div className="w-32 text-right">АТБӨЯХТатвар : </div>

              <div className="font-bold pl-2">{`${strToCurrency(tax)}₮`}</div>
            </div>
            <div className="flex w-full">
              <div className="w-32 text-right">АЗАТөлбөр : </div>

              <div className="font-bold pl-2">{`${strToCurrency(
                trafic
              )}₮`}</div>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <div className="flex w-full">
              <div className="w-32 text-right">АБТөлбөр : </div>

              <div className="font-bold pl-2">{`${strToCurrency(air)}₮`}</div>
            </div>
            <div className="flex w-full">
              <div className="w-32 text-right">Нийт : </div>

              <div className="font-bold pl-2">{`${strToCurrency(
                parseInt(tax) + parseInt(trafic) + parseInt(air)
              )}₮`}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export const checkInsurance = (data) => {
  const hasInsurance = objectPath.get(data, "hasInsurance");
  const company = objectPath.get(data, "company");
  console.log("🚀 ~ checkInsurance ~ data:", data);
  return (
    <div className="flex flex-col justify-center items-center w-full">
      {company ? (
        <div className="flex w-full">
          <div className="flex w-1/2">
            <div className="text-gray-400 italic">Компани</div>
            <div className="font-medium">{company}</div>
          </div>
          <div className="flex w-1/2">
            <div className="text-gray-400 italic">Даатгалтай эсэх</div>
            <div className="font-medium">
              {hasInsurance ? "Хүчинтэй" : "Хүчингүй"}
            </div>
          </div>
        </div>
      ) : (
        <div>{`Мэдээлэл олдсонгүй`}</div>
      )}
    </div>
  );
};
export const checkInspection = (data) => {
  const checkData = objectPath.get(data, "response.checkDate");
  const expireDate = objectPath.get(data, "response.expireDate");
  //   const passed = objectPath.get(data, "response.passed");
  const date1 = moment(expireDate);
  const date2 = moment(new Date());
  const passed = date1 - date2 > 0 ? true : false;

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="flex ">
        <div className="font-bold">{`${moment()
          .year()
          .toString()}-ны үзлэгт`}</div>

        <div
          className={`font-bold ml-2 ${
            passed ? "text-green-500" : "text-red-500"
          }`}
        >{` ${passed ? "тэнцсэн" : "тэнцээгүй"}`}</div>
      </div>
      <div className="flex w-full mt-2">
        <div className="flex flex-col w-1/2">
          <div className="flex w-full">
            <div className="w-32 text-right">Орсон огноо : </div>

            <div className="font-bold pl-2">{`${moment(checkData).format(
              "yyyy-MM-DD"
            )}`}</div>
          </div>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="flex w-full">
            <div className="w-32 text-right">Дуусах огноо : </div>

            <div className="font-bold pl-2">{`${moment(expireDate).format(
              "yyyy-MM-DD"
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const checkPenalty = (data) => {
  const list = objectPath.get(data, "return.response.listData");
  let notPaidAmount = 0;
  let PaidAmount = 0;
  let total = 0;
  let paid = 0;
  let notPaid = 0;
  list?.map((row) => {
    total = total + 1;
    if (row.paid == true) {
      PaidAmount = PaidAmount + row.amount;
      paid++;
    }
    if (row.paid == false) {
      notPaid++;
      notPaidAmount = notPaidAmount + row.amount;
    }
  });
  return (
    <div className="flex flex-col justify-center items-center w-full">
      {list ? (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="font-bold">{`Нийт торгууль : ${total}`}</div>

          <div className="flex w-full mt-2 items-center">
            <div className="flex flex-col w-1/2">
              <div className="flex w-full">
                <div className="w-32 text-right">{`Төлсөн /${paid}/ : `}</div>

                <div className="font-bold pl-2">{`${strToCurrency(
                  PaidAmount
                )}`}</div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex w-full">
                <div className="w-32 text-right">
                  {`Төлөөгүй /${notPaid}/ :`}{" "}
                </div>

                <div className="font-bold pl-2">{`${strToCurrency(
                  notPaidAmount
                )}`}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>{`Мэдээлэл олдсонгүй`}</div>
      )}
    </div>
  );
};
export const checkAsap = (data) => {
  const type = objectPath.get(data, "type");
  const created = objectPath.get(data, "created");
  const description = objectPath.get(data, "description");
  return (
    <div className="flex flex-col justify-center items-center w-full">
      {data ? (
        <div className="flex flex-col w-full">
          <div className="flex w-full ">
            <div className="flex w-1/2 ">
              <div>{`Төрөл :`}</div>
              <div className="ml-2 font-medium">{` ${type}`}</div>
            </div>
            <div className="flex w-1/2 justify-center">
              <div>{`Огноо :`}</div>
              <div className="ml-2 font-medium">{` ${moment(created).format(
                "yyyy-MM-DD HH:mm"
              )}`}</div>
            </div>
          </div>
          <div className="mt-2">{`Нэмэлт мэдээлэл : ${description}`}</div>
        </div>
      ) : (
        <div>{`Мэдээлэл олдсонгүй`}</div>
      )}
    </div>
  );
};

export const checkLimit = (data) => {
  //   console.log("🚀 ~ checkLimit ~ data:", data);
};
export const checkSpeed = (data) => {
  //   console.log("🚀 ~ checkLimit ~ data:", data);
};
export const checkDrunk = (data) => {
  const description = objectPath.get(data, "description");
  const driver = objectPath.get(data, "driver");
  const law = objectPath.get(data, "law");
  const license = objectPath.get(data, "license");
  const register = objectPath.get(data, "register");
  return (
    <div className="flex flex-col justify-center items-center w-full">
      {data ? (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex w-full justify-center">
            <div>{`Хууль : `}</div>
            <div className="ml-2 font-medium">{law}</div>
          </div>
          <div className="flex w-full mt-2 items-center">
            <div className="flex flex-col w-1/3">
              <div className="text-gray-400 italic">Жолооч</div>
              <div className="font-medium">{driver}</div>
            </div>
            <div className="flex flex-col w-1/3">
              <div className="text-gray-400 italic">Регисрийн №</div>
              <div className="font-medium">{register}</div>
            </div>
            <div className="flex flex-col w-1/3">
              <div className="text-gray-400 italic">Үнэмлэхний №</div>
              <div className="font-medium">{license}</div>
            </div>
          </div>
          <div className="mt-2 text-justify">{`Нэмэлт мэдээлэл : ${description}`}</div>
        </div>
      ) : (
        <div>{`Мэдээлэл олдсонгүй`}</div>
      )}
    </div>
  );
};
