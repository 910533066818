import React, { useEffect, useState, useRef } from "react";
import { SearchNormal } from "iconsax-react";
import isEmpty from "is-empty";
const PlateSearch = ({ onChange, restProps }) => {
  const firstRef = useRef();
  const [first, setfirst] = useState("");
  const secondRef = useRef();
  const [second, setsecond] = useState("");
  const thirdRef = useRef();
  const [third, setthird] = useState("");
  const fourRef = useRef();
  const [fourth, setfourth] = useState("");

  const numFirstRef = useRef();
  const [sfirst, setsfirst] = useState("");

  const numsecondRef = useRef();
  const [ssecond, setssecond] = useState("");

  const numthirdRef = useRef();
  const [sthird, setsthird] = useState("");
  useEffect(() => {
    if (first != "") {
      secondRef.current.focus();
    }
    if (second != "" && first != "") {
      thirdRef.current.focus();
    }
    if (third != "" && second != "" && first != "") {
      fourRef.current.focus();
    }
    if (third != "" && second != "" && first != "") {
      fourRef.current.focus();
    }
    if (fourth != "" && third != "" && second != "" && first != "") {
      numFirstRef.current.focus();
    }
    if (
      sfirst != "" &&
      fourth != "" &&
      third != "" &&
      second != "" &&
      first != ""
    ) {
      numsecondRef.current.focus();
    }
    if (
      sfirst != "" &&
      ssecond != "" &&
      fourth != "" &&
      third != "" &&
      second != "" &&
      first != ""
    ) {
      numthirdRef.current.focus();
    }
  }, [first, second, third, fourth, sfirst, ssecond, sthird]);
  const search = () => {
    let temp = {};
    if (first) {
      temp.numFirst = Number(first);
    } else delete temp.numFirst;
    if (second) {
      temp.numSecond = Number(second);
    } else delete temp.numSecond;
    if (third) {
      temp.numThird = Number(third);
    } else delete temp.numThird;
    if (fourth) {
      temp.numFour = Number(fourth);
    } else delete temp.numFour;
    if (sfirst) {
      temp.symFirst = sfirst.toUpperCase();
    } else delete temp.symFirst;
    if (ssecond) {
      temp.symSecond = ssecond.toUpperCase();
    } else delete temp.symSecond;
    // console.log("🚀 ~ search ~ sthird:", sthird);
    if (sthird) {
      temp.symThird = sthird.toUpperCase();
    } else delete temp.symThird;
    // !isEmpty(temp) &&
    onChange(temp);
  };
  return (
    <div className=" flex-col p-1 items-center">
      <div className="flex">
        <input
          className="border text-center rounded-l h-10 font-medium w-10"
          placeholder="9"
          ref={firstRef}
          maxLength={1}
          onChange={(e) => {
            setfirst(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setfirst("");
          //   }
          // }}
          {...restProps}
        />
        <input
          className="border text-center  h-10 font-medium w-10"
          placeholder="9"
          ref={secondRef}
          maxLength={1}
          onChange={(e) => {
            setsecond(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setsecond("");
          //     firstRef.current.focus();
          //   }
          // }}
          {...restProps}
        />
        <input
          className="border text-center  h-10 font-medium w-10"
          placeholder="9"
          ref={thirdRef}
          maxLength={1}
          onChange={(e) => {
            setthird(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setthird("");
          //     secondRef.current.focus();
          //   }
          // }}
          {...restProps}
        />
        <input
          className="border text-center rounded-r h-10 font-medium w-10"
          placeholder="9"
          ref={fourRef}
          maxLength={1}
          onChange={(e) => {
            setfourth(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setfourth("");
          //     thirdRef.current.focus();
          //   }
          // }}
          {...restProps}
        />
      </div>
      <div className="flex">
        <input
          className="border text-center rounded-l h-10 font-medium w-10"
          placeholder="У"
          ref={numFirstRef}
          maxLength={1}
          onChange={(e) => {
            setsfirst(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setsfirst("");
          //     fourRef.current.focus();
          //   }
          // }}
          {...restProps}
        />
        <input
          className="border text-center h-10 font-medium w-10"
          placeholder="У"
          ref={numsecondRef}
          maxLength={1}
          onChange={(e) => {
            setssecond(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     setssecond("");
          //     numFirstRef.current.focus();
          //   }
          // }}
          {...restProps}
        />

        <input
          className="border text-center h-10 font-medium w-10"
          placeholder="У"
          ref={numthirdRef}
          maxLength={1}
          onChange={(e) => {
            // console.log("🚀 ~ PlateSearch ~ e:", e.target.value);

            setsthird(e.target.value);
          }}
          onWheel={(e) => {
            e.target.blur();
          }}
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     console.log("---");

          //     setsthird(" ");
          //     numsecondRef.current.focus();
          //   }
          // }}
          {...restProps}
        />
        <div
          onClick={search}
          className={`flex h-10 w-10 rounded-r justify-center items-center border hover:bg-odt-primary hover:cursor-pointer`}
        >
          <SearchNormal
            size="20"
            color="#9EA1A1"
            className="hover:text-white"
          />
        </div>
      </div>
    </div>
  );
};
export default PlateSearch;
