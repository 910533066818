import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoLaw } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import objectPath from "object-path";
import { mainApi } from "../providers/api";
import { CiCreditCard1 } from "react-icons/ci";
import { GoNumber } from "react-icons/go";
import { IoCarSportOutline } from "react-icons/io5";
import { VscLocation } from "react-icons/vsc";
import {
  checkAsap,
  checkDrunk,
  checkInspection,
  checkInsurance,
  checkLimit,
  checkPenalty,
  checkTax,
} from "./components";
import List from "./List";
import { Loader } from "../template";

const Detail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setloading] = useState(false);

  const [car, setcar] = useState();

  const fetchMain = async () => {
    setloading(true);
    const response = await mainApi({
      url: `/car/view/${id}`,
      method: "GET",
    });
    setcar(objectPath.get(response, "data"));
    setloading(false);
  };
  useEffect(() => {
    fetchMain();
  }, []);

  return (
    <div className="flex w-full">
      <div className="flex w-1/2 p-4">
        <div className="flex flex-col w-full">
          <div className="text-lg font-bold">{`Мэдээлэл`}</div>
          {loading ? (
            <div className="flex w-full justify-center items-center min-h-64">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div className="flex flex-col bg-odt-primary w-full rounded-t p-2">
                  <div className="text-white pl-2 font-medium">
                    Тээврийн хэрэгсэл
                  </div>
                </div>
                <div className="flex p-4">
                  <img
                    className="w-1/2 rounded"
                    src={`${objectPath.get(car, "photo")}`}
                  />
                  <div className="flex flex-col px-2">
                    <div className="flex items-center">
                      <IoCarSportOutline className="font-bold" />

                      <div className="font-medium ml-2">
                        {objectPath.get(car, "mark")}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <IoCarSportOutline className="font-bold" />

                      <div className="font-medium ml-2">
                        {objectPath.get(car, "model")}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <GoNumber className="font-bold" />

                      <div className="font-medium ml-2">
                        {objectPath.get(car, "plate")}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <CiCreditCard1 className="font-bold" />

                      <div className="font-medium ml-2">
                        {objectPath.get(car, "vin")}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <VscLocation className="font-bold" />

                      <div className="font-medium ml-2">
                        {objectPath.get(car, "location") == "COUNTRY"
                          ? "Орон нутаг"
                          : "Нийслэл"}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <GoLaw className="font-bold" />

                      <div
                        className={`font-medium ml-2 ${
                          objectPath.get(car, "isViolation")
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {objectPath.get(car, "isViolation") == true
                          ? "Зөрчилтэй"
                          : "Зөрчилгүй"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "tax") ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Татварын мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkTax(objectPath.get(car, "taxResponse"))}
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "insurance")
                      ? "bg-red-500"
                      : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Даатгалын мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkInsurance(objectPath.get(car, "insuranceResponse"))}
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "inspection")
                      ? "bg-red-500"
                      : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Оношилгооны мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkInspection(objectPath.get(car, "inspectionResponse"))}
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "penalty")
                      ? "bg-red-500"
                      : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Торгуулийн мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkPenalty(objectPath.get(car, "penaltyResponse"))}
                </div>
              </div>
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "asap") ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Эрэн сурвалжлалтын мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkAsap(objectPath.get(car, "asapResponse"))}
                </div>
              </div>
              {/* <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
            <div
              className={`flex flex-col w-full rounded-t p-2 ${
                objectPath.get(car, "limit") ? "bg-red-500" : "bg-green-500"
              }`}
            >
              <div className="text-white pl-2 font-medium">
                Дугаарын хязгаарлалтын мэдээлэл
              </div>
            </div>
            <div className="flex p-4">
              {checkLimit(objectPath.get(car, "limitResponse"))}
            </div>
          </div> */}
              {/* <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
            <div
              className={`flex flex-col w-full rounded-t p-2 ${
                objectPath.get(car, "speed") ? "bg-red-500" : "bg-green-500"
              }`}
            >
              <div className="text-white pl-2 font-medium">Хурдны мэдээлэл</div>
            </div>
            <div className="flex p-4"></div>
          </div> */}
              <div className="flex flex-col w-full mt-4 bg-white rounded-lg shadow">
                <div
                  className={`flex flex-col w-full rounded-t p-2 ${
                    objectPath.get(car, "drunk") ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  <div className="text-white pl-2 font-medium">
                    Жолоодох эрхийн мэдээлэл
                  </div>
                </div>
                <div className="flex p-4">
                  {checkDrunk(objectPath.get(car, "drunkResponse"))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-1/2 p-4">
        <div className=" pl-2 font-medium">{`Бүртгэлийн түүх`}</div>
        {car && <List plate={objectPath.get(car, "plate")} />}
      </div>
    </div>
  );
};

export default Detail;
